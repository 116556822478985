.contact-title {
    color: white;
    font-size: 3.5rem;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 5px;
    position: relative;
    left: 0;
}

.contact-wrapper {
    width: 60vw;
    margin-left: 20vw;
    height: 65vh;
    display: block;
    overflow: hidden;
    /* border: 1px solid white; */
}

form {
    text-align: left;
    height: 65vh;
}

.input {
    width: 100%;
    height: 4vh;
    margin-top: 5vh;
    border: none;
    outline: none;
    color: var(--white);
    border-bottom: 1px solid white;
    background-color: transparent;
    transition: 1s ease;
}

.message {
    height: 25vh;
}

.placeholder {
    color: var(--white);
    position: relative;
    transition: 0.4s ease-out;
    letter-spacing: 0.2rem;
    padding-left: 10px;
}



/* .input-description :focus */

.submit-button {
    width: 70px;
    padding: 6px;
    position: absolute;
    margin-top: 30px;
    left: calc(30vw - 40px);
    border: 1px solid white;
    background-color: transparent;
    color: var(--white);
    cursor: pointer;
}

.submit-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

input,
textarea {
    border-radius: 0;
}