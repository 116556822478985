nav {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    color: var(--font-primary);
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    top: 0;
    z-index: 998;
    align-items: center;
}

.sections {
    padding-right: 30px;
    display: flex;
}

.icon {
    padding-left: 80px;
    width: 70px;
    transition: all 1s;
}

.expandicon {
    padding-left: 50px;
}

.sections li {
    list-style-type: none;
    padding: 0 10px;
    transition: all 1s;
}

.sections li a {
    font-size: 1rem;
}

.sections {
    padding-right: 30px;
}

.expandli li {
    padding: 0 20px;
}

.nav-divider {
    position: fixed;
    top: 79px;
    width: 1%;
    margin-left: 49.5%;
    height: 1px;
    background-color: var(--white);
    transition: all 1s;
    z-index: 999;
}

.expanded {
    width: 90%;
    margin-left: 5%;
}

.Navbar a {
    text-decoration: none;
    color: inherit;
    transition: font-size 1s ease;
}

.Navbar a:hover,
a:active,
a:focus {
    text-decoration: none;
    color: inherit;
}

.Navbar a:hover {
    font-size: 1.2rem;
    font-weight: 700;
}

@media only screen and (max-width: 950px) {
    .sections li {
        padding: 0 7px;
    }
}

@media only screen and (max-width: 550px) {
    .sections li a {
        font-size: 0.62rem;
    }

    .Navbar a:hover {
        font-size: 1rem;
    }

    .icon {
        padding-left: 20px;
        width: 60px;
    }
}