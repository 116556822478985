.project-title {
    color: white;
    font-size: 3.5rem;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 5px;
    position: relative;
    left: 0;
}

.projects-carousel-container {
    width: 100%;
    height: 550px;
    background-color: #000;
}

canvas {
    width: 100%;
    height: 100%;
}