:root {
    background-color: var(--dark);
}

html {
    scroll-behavior: smooth;
}

* {
    font-family: monospace;
    font-size: 1rem;
    position: relative;
    overflow-x: hidden;
}

/* Scrollbar */
body::-webkit-scrollbar {
    width: 0px;
}

#scrollPath {
    position: fixed;
    top: 0;
    right: 0;
    width: 8px;
    height: 100vh;
    background: var(--dark);
    z-index: 1000;
}

#progressbar {
    position: fixed;
    top: 0;
    right: 0;
    width: 8px;
    height: 0;
    background: linear-gradient(to top, #008aff, #00ffe7);
    animation: gradient 5s linear infinite;
    z-index: 1002;
    box-shadow: 5px 0 20px 1px var(--white);
}

@keyframes gradient {

    0%,
    100% {
        filter: hue-rotate(0deg);
    }

    50% {
        filter: hue-rotate(360deg);
    }
}

/* end of scrollbar */



.body {
    padding-top: 80px;
}

section {
    width: 100%;
    height: auto;
    background-color: var(--dark);
    overflow-y: hidden;
    align-items: center;
    text-align: center;
    padding-bottom: 50px;
}

.black-bg {
    height: calc(100vh - 60px);
    padding-bottom: 0;
}

/* .divider {
    width: 1%;
    margin-left: 49.5%;
    height: 1px;
    background-color: var(--white);
    transition: all 1s;
    z-index: 2;
} */

@keyframes lineAnimation {
    0% {
        transform: translateX(-120%);
        opacity: 1;
    }

    40% {
        transform: translateX(0%);
        opacity: 1;
    }

    60% {
        transform: translateX(0%);
        opacity: 1;
    }

    100% {
        transform: translateX(120%);
        opacity: 0;
    }
}

.divider {
    left: 0;
    width: 100%;
    height: 1px;
    background-color: white;
    animation: lineAnimation 4s ease-in-out infinite;
}

/* .expanded {
    width: 90%;
    margin-left: 5%;
} */

.locator {
    position: relative;
    top: -70px;
}

.black-bg {
    background-color: rgba(0, 0, 0, 0.9);
}


.bold {
    font-weight: 600;
}