footer {
    width: 100%;
    height: 100px;
    background-color: var(--black);
    text-align: center;
}

.designer {
    padding: 10px 0 0;
    color: var(--font-info);
}

.favicons .icon {
    padding: 0;
    color: var(--font-info);
}