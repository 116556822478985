.easter-egg {
  display: none;
  color: var(--white);
  font-size: 0.8rem;
  padding: 50px;
  top: -40px;
  animation: glowing 1.2s linear infinite;
}

.profile {
    height: calc(100vh - 65px);
    max-width: 100%;
    display: block;
    transition: transform 0.5s ease;
    position: absolute;
    bottom: 0;
}

.headline {
    position: absolute;
    right: 12%;
    top: 20vh;
    text-align: center;
    color: var(--white);
    animation: glowing 1.2s linear infinite;
    z-index: 3;
}

h1 {
    padding: 20px;
    font-size: 6rem;
    font-family: Lobster;
    margin-bottom: -25px;
    animation: fadeInUp 1s forwards;
}

.role, .school {
    font-size: 1.5rem;
}


.moving-bg {
  position: absolute;
  left: 0;
  /* transform: rotate(30deg); */
  top: 30vh;
  /* right: -250px; */
  overflow-y: hidden;
  z-index: -1;
}

.row {
  display: inline-block;
  white-space: nowrap;
  overflow-y: hidden;
  /* animation: moving 10s linear infinite; */
}

.row :nth-child(even) {
  animation: moving 10s linear infinite;
}

.row :nth-child(odd) {
  animation: moving-rev 10s linear infinite;
}

@keyframes moving {
  0% {transform: translateX(0%);}
  100% {transform: translateX(-12.5%);}
}

@keyframes moving-rev {
  0% {transform: translateX(-25%);}
  100% {transform: translateX(-12.5%);}
}

.row p {
  font-family: Lobster;
  color: var(--white);
  font-size: 3rem;
  margin-top: -60px;
}

@keyframes glowing {
    0% {
      text-shadow: 0 0 12px var(--white);
    }
    50% {
      text-shadow: 0 0 12px var(--regular);
    }
    100% {
      text-shadow: 0 0 12px var(--white);
    }
}

@keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(40px);
    }
    60% {
      opacity: 0.6;
      transform: translateY(-10px);
      animation-timing-function: cubic-bezier(0.5, 0.6, 0.5, 1);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0.6, 0.4, 0.6, 1);
    }
}

@media only screen and (max-width: 950px) {
    .profile {
      transform: translateX(-15vh);
    }
    h1 {
        font-size: 10vw;
    }
    .headline {
        top: 25vh;
        right: 5vw;
    }
    .role, .school {
        font-size: 1rem;
    }
    .moving-bg {
      top: 28vh;
    }
}

@media only screen and (max-width: 550px) {
  .profile {
    height: auto;
  }
  .headline {
    top: 35vh;
    right: 0vw;
  }
  .moving-bg {
    display: none;
  }
  @keyframes glowing {
    0% {
      text-shadow: 0 0 5px var(--white);
    }
    50% {
      text-shadow: 0 0 5px var(--regular);
    }
    100% {
      text-shadow: 0 0 5px var(--white);
    }
  }
  .easter-egg {
    display: block;
  }
}